import React, { useState, useEffect } from 'react';
import { formatPrice } from 'tools/common'
import './index.less';
import { Modal, Form, Input, Button, Statistic, message } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { AccountStore, CommonStore } from 'stores'
import md5 from 'md5';
import Cookies from "js-cookie";
interface MainProps {
    [key: string]: any;
}

const { Countdown } = Statistic

// 密码校验正则
const psdReg = /^(?!^\d+$)(?!^[a-zA-Z]+$)[0-9a-zA-Z]{6,16}$/

// 仅供记录使用
// const stepMap = new Map([
//     [0, '设置支付密码'],
//     [1, '支付验证'],
//     [-1, '忘记支付密码'],
//     [-100, '支付金额有变动'],
//     [-200, '订单状态有变化'],
//     [400070, '订单有变化'],
// ])

const PayModal: React.FC<MainProps> = (props): JSX.Element => {

    const { paymentData, balance, payBillIds } = props

    const [step, setStep] = useState<number>(-1000)
    const onClose = () => {
        props.onClose()
    }

    useEffect(() => {
        checkHasSetPassword()
    }, []);

    useEffect(() => {
        if ([0, -1].includes(step)) {
            getAdminTel()
        }
    }, [step]);

    const [adminTel, setAdminTel] = useState<string>('')// 管理员手机号码

    // 获取管理员手机号码
    const getAdminTel = async () => {
        try {
            const res = await AccountStore.getAdminTel()
            if (res?.success) {
                setAdminTel(res.data)
            }

        } catch (error) {

        }
    }

    // 先验证有没有设置支付密码
    const checkHasSetPassword = async () => {
        try {
            const res = await AccountStore.checkHasSetPassword()
            if (res?.success) {
                if (res.data) {
                    setStep(1)
                } else {
                    setStep(0)
                }
            }

        } catch (error) {

        }
    }


    const layout: any = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    };
    const [form] = Form.useForm()

    useEffect(() => {
        if (step === 1) {
            // 获取账户余额
            // 获取订单金额
        }
    }, [step]);

    const [loading, setLoading] = useState<boolean>(false)// 支付loading

    // 确认支付
    const pay = () => {
        form.validateFields().then(async (values) => {
            try {
                setLoading(true)
                const params: any = {
                    amtPay: paymentData.payMoney,
                    channelInfoList: [
                        {
                            bankCode: '10',
                            channelAmtPay: paymentData.payMoney,
                            payPassword: md5(values.password)
                        }
                    ],
                    payBillInfoList: []
                }
                payBillIds?.forEach((item: any) => {
                    params.payBillInfoList.push({ payBillId: item })
                })
                const res = await AccountStore.pay(params)
                if (res?.success) {
                    props.onPaySuccess()
                }
            } catch (err:any) {
                if (err.code === '400070') {
                    // 支付失效，请重新发起支付
                    setStep(400070)
                }

            } finally {
                setLoading(false)
            }
        })
    }

    // 点击忘记密码
    const handleClickForgetPassword = () => {
        setStep(-1)
    }

    if (step === -1000) {
        return <div></div>
    }

    if (step === 0) {
        return <SetPassWordModal onClose={props.onClose} setStep={setStep} adminTel={adminTel}></SetPassWordModal>
    }

    if (step === -1) {
        return <ReSetPassWordModal onClose={props.onClose} setStep={setStep} adminTel={adminTel}></ReSetPassWordModal>
    }

    if (step === -100) {
        return <PriceChangeModal onClose={props.onClose}></PriceChangeModal>
    }
    if (step === -200) {
        return <OrderStatusChangeModal onClose={props.onClose}></OrderStatusChangeModal>
    }

    if (step === 400070) {
        return <OrderChangeModal onClose={props.onClose}></OrderChangeModal>
    }



    return (
        <Modal
            title='支付验证'
            visible={true}
            // onOk={onOk}
            onCancel={onClose}
            footer={null}
        >
            <div className='payModal'>
                <Form labelAlign='right' {...layout} form={form}>
                    <Form.Item label='订单金额'>
                        <div className='payModal_btext'>￥{formatPrice(paymentData.payMoney)}</div>
                    </Form.Item>
                    <Form.Item label='账户可用余额'>
                        <div className='payModal_btext'>￥{formatPrice(balance)}</div>
                    </Form.Item>
                    <Form.Item label='支付密码' name='password' rules={[
                        { required: true, message: '请输入支付密码', validateTrigger: 'onBlur' }]}>
                        <div>
                            <Input.Password allowClear />
                            <span className='forget' onClick={handleClickForgetPassword}>忘记密码</span>
                        </div>
                    </Form.Item>

                </Form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type='primary' onClick={pay} loading={loading}>
                        {loading ? '支付中' : '确认支付'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};


export default PayModal;

// 设置支付密码弹窗
const SetPassWordModal = (props: any) => {
    const onClose = () => {
        props.onClose()
    }
    const layout: any = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    };

    const [form] = Form.useForm()
    const [deadTime, setDeadTime] = useState<any>('')

    // 发送短信验证码
    const sendSmsCode = async () => {
        try {
            const res = await AccountStore.sendSmsToAdmin()
            if (res?.success) {
                setDeadTime(new Date().getTime() + 1000 * 60)
                message.success('短信验证码发送成功')
            }
        } catch (error) {

        }
    }
    const handleSetPassword = () => {
        form.validateFields().then(async (values) => {
            try {
                setLoading(true)
                const params = {
                    password: md5(values.password),
                    confirmPwd: md5(values.password2),
                    smsCode: values.smsCode
                }
                const res = await AccountStore.setPassword(params)
                if (res?.success) {
                    message.success('操作成功')
                    props.setStep(1)
                }
            } finally {
                setLoading(false)
            }
        })
    }

    const [loading, setLoading] = useState<boolean>(false)

    return (
        <Modal
            title='设置支付密码'
            visible={true}
            // onOk={onOk}
            onCancel={onClose}
            footer={null}
        >
            <div className='setPasswordModal'>
                <Form labelAlign='right' {...layout} form={form}>
                    <Form.Item label='支付密码' name='password' rules={[
                        { required: true, message: '请输入支付密码', validateTrigger: 'onBlur' },
                        () => ({
                            validator(_, value) {
                                if (psdReg.test(value)) {
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject("数字与字母组合，6-16位");
                                }
                            }
                        })
                    ]}>
                        <Input.Password allowClear />
                    </Form.Item>
                    <Form.Item label='确认支付密码' name='password2' rules={[
                        { required: true, message: '请确认支付密码', validateTrigger: 'onBlur' },
                        () => ({
                            validator(_, value) {
                                if (value && value !== form.getFieldValue("password")) {
                                    return Promise.reject("两次输入的密码不一致!");
                                }
                                return Promise.resolve();
                            },
                            validateTrigger: 'onBlur'
                        })]}>
                        <Input.Password allowClear />
                    </Form.Item>
                    <Form.Item label='手机验证码' required>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Item name='smsCode' noStyle rules={[{ required: true, message: '请输入验证码' }]}>
                                    <Input allowClear />
                                </Form.Item>
                                <Button style={{ marginLeft: 10 }} onClick={sendSmsCode} disabled={!!deadTime}>
                                    {!deadTime && '发送验证码'}
                                    {
                                        !!deadTime && <Countdown value={deadTime} format="s秒后可重新发送" onFinish={() => { setDeadTime('') }} />
                                    }
                                </Button>
                            </div>
                            <span style={{ fontSize: 12 }}>验证码将发送至管理员手机号{props.adminTel}</span>
                        </div>
                    </Form.Item>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type='primary' onClick={handleSetPassword} loading={loading}>确认设置</Button>
                </div>
            </div>
        </Modal>
    );
}

// 忘记支付密码弹窗
const ReSetPassWordModal = (props: any) => {
    const onClose = () => {
        props.onClose()
    }
    const layout: any = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
    };

    const [form] = Form.useForm()
    const [deadTime, setDeadTime] = useState<any>('')
    // 发送短信验证码
    const sendSmsCode = async () => {
        try {
            const res = await AccountStore.sendSmsToAdmin()
            if (res?.success) {
                setDeadTime(new Date().getTime() + 1000 * 60)
                message.success('短信验证码发送成功')
            }
        } catch (error) {

        }
    }
    const handleReSetPassword = () => {
        form.validateFields().then(async (values) => {
            try {
                setLoading(true)
                const params = {
                    password: md5(values.password),
                    confirmPwd: md5(values.password2),
                    smsCode: values.smsCode
                }
                const res = await AccountStore.setPassword(params)
                if (res?.success) {
                    message.success('操作成功')
                    props.setStep(1)
                }
            } finally {
                setLoading(false)
            }
        })
    }

    const [loading, setLoading] = useState<boolean>(false)

    return (
        <Modal
            title='忘记支付密码'
            visible={true}
            // onOk={onOk}
            onCancel={onClose}
            footer={null}
        >
            <div className='setPasswordModal'>
                <div style={{ fontSize: 14, lineHeight: '20px', textAlign: 'center' }}>重设支付密码需要验证管理员手机验证码</div>
                <div style={{ fontSize: 14, lineHeight: '20px', textAlign: 'center', marginBottom: '8px' }}>管理员手机号：{props.adminTel}</div>
                <Form labelAlign='right' {...layout} form={form}>
                    <Form.Item label='短信验证码' required>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Item name='smsCode' noStyle rules={[{ required: true, message: '请输入短信验证码' }]}>
                                <Input allowClear placeholder='请输入验证码' />
                            </Form.Item>
                            <Button style={{ marginLeft: 10 }} onClick={sendSmsCode} disabled={!!deadTime}>
                                {!deadTime && '发送验证码'}
                                {
                                    !!deadTime && <Countdown value={deadTime} format="s秒后可重新发送" onFinish={() => { setDeadTime('') }} />
                                }
                            </Button>
                        </div>
                    </Form.Item>
                    <Form.Item label='密码' name='password' rules={[
                        { required: true, message: '请输入支付密码', validateTrigger: 'onBlur' },
                        () => ({
                            validator(_, value) {
                                if (psdReg.test(value)) {
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject("数字与字母组合，6-16位");
                                }
                            }
                        })
                    ]}>
                        <Input.Password allowClear placeholder='数字与字母组合，6-16位' />
                    </Form.Item>
                    <Form.Item label='确认密码' name='password2' rules={[
                        { required: true, message: '请重复再输一遍密码', validateTrigger: 'onBlur' },
                        () => ({
                            validator(_, value) {
                                if (value && value !== form.getFieldValue("password")) {
                                    return Promise.reject("两次输入的密码不一致!");
                                }
                                return Promise.resolve();
                            },
                            validateTrigger: 'onBlur'
                        })]}>
                        <Input.Password allowClear placeholder='请重复再输一遍' />
                    </Form.Item>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={() => { props.setStep(1) }}>取消</Button>
                    <Button type='primary' onClick={handleReSetPassword} style={{ marginLeft: 20 }} loading={loading}>确认</Button>
                </div>
            </div>
        </Modal>
    );
}

// 支付金额变化弹窗
const PriceChangeModal = (props: any) => {
    // 刷新页面
    const reload = () => {
        window.location.reload()
    }
    // 查看订单变动
    const handleClickOrder = () => {
        window.open(`${CommonStore.redirectUrl}?token=${Cookies.get('pay_token')}`)
    }
    return <Modal
        title='提示'
        visible={true}
        onCancel={props.onClose}
        footer={[
            <Button key={1} onClick={reload}>刷新页面</Button>,
            <Button key={2} type='primary' onClick={handleClickOrder}>查看订单变动</Button>,
        ]}
        closable={false}
    >
        <div className='priceChangeModal'>
            <div><InfoCircleOutlined style={{ fontSize: 18, color: 'red' }} /><span style={{ marginLeft: 10, fontSize: 16 }}>支付金额有变动</span></div>
            <div style={{ marginTop: 20 }}>卖家已修改您本次支付的订单金额</div>
            <div>请刷新支付页面或前往订单详情查看变动金额</div>
        </div>
    </Modal>
}

// 订单状态变化弹窗
const OrderStatusChangeModal = (props: any) => {
    // 查看订单变动
    const handleClickOrder = () => {
        window.open(`${CommonStore.redirectUrl}?token=${Cookies.get('pay_token')}`)
    }
    return <Modal
        title='提示'
        visible={true}
        onCancel={props.onClose}
        footer={[
            <Button key={1} onClick={handleClickOrder} type='primary'>查看订单变动</Button>,
        ]}
        closable={false}
    >
        <div className='priceChangeModal'>
            <div><InfoCircleOutlined style={{ fontSize: 18, color: 'red' }} /><span style={{ marginLeft: 10, fontSize: 16 }}>不可包含已取消订单</span></div>
            <div style={{ marginTop: 20 }}>您本次支付的订单中包含已取消的订单</div>
            <div>请前往订单详情查看</div>
        </div>
    </Modal>
}

// 订单变化弹窗
const OrderChangeModal = (props: any) => {
    // 查看订单变动
    const handleClickOrder = () => {
        window.open(`${CommonStore.redirectUrl}?token=${Cookies.get('pay_token')}`)
    }
    return <Modal
        title='提示'
        visible={true}
        onCancel={props.onClose}
        footer={[
            <Button key={1} onClick={handleClickOrder} type='primary'>查看订单变动</Button>,
        ]}
        closable={false}
    >
        <div className='priceChangeModal'>
            <div><InfoCircleOutlined style={{ fontSize: 18, color: 'red' }} /><span style={{ marginLeft: 10, fontSize: 16 }}>支付失败，请重新发起支付</span></div>
        </div>
    </Modal>
}