import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { CommonStore } from "stores";
import Cookies from "js-cookie";
import { MALL_HOST } from 'config'

import './index.less';
interface MainProps {
    [key: string]: any;
}

// 支付成功页
const Success: React.FC<MainProps> = (): JSX.Element => {

    // 查看订单
    const handleGoToOrder = () => {
        if (!CommonStore.redirectUrl) return
        window.location.href = `${CommonStore.redirectUrl}?token=${Cookies.get('pay_token')}`
    }

    // 返回商城
    const handleClickBackToMall = () => {
        window.location.href = `${MALL_HOST}?token=${Cookies.get('pay_token')}`
    }

    return (
        <div className='success'>
            <div className='success_top'>
                <CheckCircleOutlined style={{ fontSize: 30, color: '#01D929' }} />
                <span style={{ fontSize: 20, fontWeight: 600, marginLeft: 10 }}>支付完成</span>
            </div>
            <div className="success_btns">
                {!!CommonStore.redirectUrl && <Button onClick={handleGoToOrder} style={{ marginRight: 20 }}>查看订单</Button>}
                <Button type='primary' onClick={handleClickBackToMall}>返回商城</Button>
            </div>
        </div>
    );
};
export default observer(Success);