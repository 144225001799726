import axios from "axios";
import moment from "moment";
import { message, notification } from "antd";
import { MAIN_HOST } from "config";
import Cookies from "js-cookie";

/**
 * 格式化url参数
 */
export const parseQueryString = () => {
    const url = window.location.href;
    const regUrl = /^[^\?]+\?([\w\W]+)$/; //eslint-disable-line
    const regPara = /([^&=]+)=([\w\W]*?)(&|$|#)/g;
    const arrUrl = regUrl.exec(url);
    let ret: any = {};
    if (arrUrl && arrUrl[1]) {
        const strPara = arrUrl[1];
        let result;
        while ((result = regPara.exec(strPara)) != null) {
            ret[result[1]] = result[2];
        }
    }
    return ret;
};

/**
 * 时间戳转换
 */
export const formatTime = (time: any, fmt = "YYYY-MM-DD HH:mm:ss") => {
    return time ? moment(time).format(fmt) : "";
};

// 分转元
export const formatPrice = (fen: number) => {
    let num: any = fen;
    num = fen * 0.01;
    num += "";
    let reg =
        num.indexOf(".") > -1
            ? /(\d{1,3})(?=(?:\d{3})+\.)/g
            : /(\d{1,3})(?=(?:\d{3})+$)/g;
    num = num.replace(reg, "$1");
    num = toDecimal2(num);
    return `${num}`
};

const toDecimal2 = (x: any) => {
    let f = parseFloat(x);
    if (isNaN(f)) {
        return false;
    }
    f = Math.round(x * 100) / 100;
    let s = f.toString();
    let rs = s.indexOf(".");
    if (rs < 0) {
        rs = s.length;
        s += ".";
    }
    while (s.length <= rs + 2) {
        s += "0";
    }
    return s;
};

/**
 * JSON 克隆
 * @param {Object | Json} jsonObj json对象
 * @return {Object | Json} 新的json对象
 */
export function objClone(jsonObj: any) {
    var buf: any;
    if (jsonObj instanceof Array) {
        buf = [];
        var i = jsonObj.length;
        while (i--) {
            buf[i] = objClone(jsonObj[i]);
        }
        return buf;
    } else if (jsonObj instanceof Object) {
        buf = {};
        for (var k in jsonObj) {
            buf[k] = objClone(jsonObj[k]);
        }
        return buf;
    } else {
        return jsonObj;
    }
}

/**
 * 下载接口返回的文件流
 * @param {*} data 文件流
 * @param {*} type 文件类型
 * @param {*} fileName 下载文件名
 */
export function downloadFile(data: any, type = "vnd.ms-excel", fileName = "") {
    const blob = new Blob([data], { type: `application/${type};charset=utf-8` });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download =
        fileName || formatTime(new Date(), "YYYYMMDDHHmmss") + "下载文件";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function getSearchVal(key: string) {
    const params = window.location.search.slice(1);
    return new URLSearchParams(params).get(key);
}

/**
 * @param {String} JsonString
 * @description 格式化多规格参数
 */
export const formartAttribute = (attribute: string) => {
    const attr = JSON.parse(attribute);
    return Object.keys(attr).reduce((str, key, index, arr) => {
        const _and = index + 1 === arr.length ? "" : " , ";
        return (str += `${key}：${attr[key]}` + _and);
    }, "");
};

/**
 * 导出文件
 * @param url 接口地址
 * @param data 参数
 * @returns {*|string}
 */
export function ExportFile(url: string = '', data: Object = {}, name: string = '', handel: Function = () => { }, handel2: Function = () => { }) {
    axios({
        method: "get",
        baseURL: MAIN_HOST,
        headers: {
            authority_token: Cookies.get("pay_token") || localStorage.getItem("pay_token"),
        },
        url,
        params: data,
        // data,
        responseType: "blob",
    })
        .then((data) => {
            // console.log(data)
            const blob = new Blob([data.data], {
                type: "application/pdf;charset=utf-8",
            });
            const fileReader = new FileReader();
            fileReader.readAsText(blob);
            fileReader.onload = function () {
                try {
                    const result = JSON.parse(this.result as any);
                    if (result && result.success === false) {
                        notification.error({
                            message: "提示",
                            description: result.message,
                        });
                        handel2();
                    } else {
                        // reject();
                        handel2();
                    }
                } catch (e) {
                    //TODO handle the exception
                    // reject();
                    if (data.status === 200) {
                        let fileName = `${name ? name : formatTime(new Date())}.pdf`;
                        // 将`blob`对象转化成一个可访问的`url`
                        let url = window.URL.createObjectURL(blob);
                        let link = document.createElement("a");
                        link.style.display = "none";
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        handel();
                    }
                }
            };
        })
        .catch(() => {
            message.error("导出失败");
        });
}


//获取日期
export function getFormatDate(value: any) {
    var date = new Date(value);
    var seperator1 = "-";
    var year = date.getFullYear();
    var month: any = date.getMonth() + 1;
    var strDate: any = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

//复制地址
export const copyStr = (str: string) => {
    const dom = document.createElement("textarea");
    dom.value = str;
    document.body.appendChild(dom);
    dom.select(); // 选中文本
    document.execCommand("copy"); // 执行浏览器复制命令
    document.body.removeChild(dom);
};

//防抖
export const debounce = (fn: Function = () => { }, wait: number) => {
    var timeout: any = null;
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(function(){
            // @ts-ignore
            fn.apply(this, arguments);
        }, wait);
    };
};

//k 万
export const formatNum = (num: number) => {
    return num >= 1e3 && num < 1e4
        ? (num / 1e3).toFixed(1) + "k"
        : num >= 1e4
            ? (num / 1e4).toFixed(1) + "w"
            : num;
};

// 压缩前将file转换成img对象
export const readImg = (file: File) => {
    return new Promise((resolve, reject) => {
        const img: any = new Image();
        const reader = new FileReader();
        reader.onload = function (e: any) {
            img.src = e.target.result;
        };
        reader.onerror = function (e) {
            reject(e);
        };
        reader.readAsDataURL(file);
        img.onload = function () {
            resolve(img);
        };
        img.onerror = function (e: any) {
            reject(e);
        };
    });
};

/**
 * 压缩图片
 *@param img 被压缩的img对象
 * @param type 压缩后转换的文件类型
 * @param mx 触发压缩的图片最大宽度限制
 * @param mh 触发压缩的图片最大高度限制
 */
export const compressImg = (img: any, type: any, mx: number, mh: number) => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context: any = canvas.getContext("2d");
        const { width: originWidth, height: originHeight } = img;
        // 最大尺寸限制
        const maxWidth = mx;
        const maxHeight = mh;
        // 目标尺寸
        let targetWidth = originWidth;
        let targetHeight = originHeight;
        if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > 1) {
                // 宽图片
                targetWidth = maxWidth;
                targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
                // 高图片
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
        }
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        context.clearRect(0, 0, targetWidth, targetHeight);
        // 图片绘制
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        canvas.toBlob(function (blob) {
            resolve(blob);
        }, type || "image/png");
    });
};

export const blobToFile = (blob: any, fileName: string) => {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
};

export const compressImage = (file: any) => {
    return new Promise((resolve, reject) => {
        const name = file.name; //文件名
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
            const src = e.target.result;

            const img = new Image();
            img.src = src;
            img.onload = (e) => {
                const w = img.width;
                const h = img.height;
                const quality = 0.8; // 默认图片质量为0.92
                // 生成canvas
                const canvas = document.createElement("canvas");
                const ctx: any = canvas.getContext("2d");
                // 创建属性节点
                const anw: any = document.createAttribute("width");
                anw.nodeValue = w;
                const anh: any = document.createAttribute("height");
                anh.nodeValue = h;
                canvas.setAttributeNode(anw);
                canvas.setAttributeNode(anh);

                // 铺底色 PNG转JPEG时透明区域会变黑色
                ctx.fillStyle = "#fff";
                ctx.fillRect(0, 0, w, h);

                ctx.drawImage(img, 0, 0, w, h);
                // quality值越小，所绘制出的图像越模糊
                const base64 = canvas.toDataURL("image/jpeg", quality); // 图片格式jpeg或webp可以选0-1质量区间

                // 返回base64转blob的值
                // console.log(
                //   `原图${(src.length / 1024).toFixed(2)}kb`,
                //   `新图${(base64.length / 1024).toFixed(2)}kb`
                // );
                // 去掉url的头，并转换为byte
                const bytes = window.atob(base64.split(",")[1]);
                // 处理异常,将ascii码小于0的转换为大于0
                const ab = new ArrayBuffer(bytes.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < bytes.length; i++) {
                    ia[i] = bytes.charCodeAt(i);
                }
                file = new Blob([ab], { type: "image/jpeg" });
                file.name = name;

                resolve(file);
            };
            img.onerror = (e) => { };
        };
        reader.onerror = (e) => { };
    });
};

// 格式化天数
export const formatDay = (num: number) => {
    let str = ''
    if (num === 1) {
        str = '24小时'
    } else if (num === 2) {
        str = '48小时'
    } else {
        str = `${num}天`
    }
    return str
}

// 格式化天数
export const getGoodsDetailParamsKeyValue = (detail: any) => {
    const keyValuesArr = [];
    const {
        brandName,
        unit,
        style,
        befitPerson,
        befitPresent,
        packaging,
        marketTime,
        suit,
        isCustom,
        propertyList
    } = detail?.basicInfo;
    if (!!brandName) {
        keyValuesArr.push({ key: "品牌", value: brandName });
    }
    if (!!unit) {
        keyValuesArr.push({ key: "商品单位", value: unit });
    }
    if (!!style) {
        keyValuesArr.push({ key: "风格", value: style });
    }
    if (!!befitPerson) {
        keyValuesArr.push({ key: "适用人群", value: befitPerson });
    }
    if (!!befitPresent) {
        keyValuesArr.push({ key: "适用送礼对象", value: befitPresent });
    }
    if (!!packaging) {
        keyValuesArr.push({ key: "包装", value: packaging });
    }
    if (!!marketTime) {
        keyValuesArr.push({ key: "产品上市时间", value: marketTime });
    }
    if (!!suit) {
        keyValuesArr.push({ key: "套装", value: suit });
    }
    if (isCustom === 1) {
        keyValuesArr.push({ key: "是否支持定制", value: "是" });
    }
    if (isCustom === 0) {
        keyValuesArr.push({ key: "是否支持定制", value: "否" });
    }

    propertyList?.forEach((item: any) => {
        if (item.propertyList?.length > 0) {
            item.propertyList.forEach((item: any) => {
                keyValuesArr.push({ key: item.propertyName, value: item.propertyValue });
            })
        }
    })

    return keyValuesArr
}

// 格式化销量
export const formatSalesNum = (num: number) => {
    if (num < 10000) return num
    else {
        return `${Math.floor(num / 10000)}+`
    }
}

// 商品组件格式化更新时间
export const formatModifyTime = (time: any) => {
    const timeStamp = new Date(time).getTime()
    const nowStamp = new Date().getTime()
    const day = 24 * 60 * 60 * 1000
    const range = nowStamp - timeStamp
    if (range / day <= 1) {
        return '今日'
    } else if (range / day > 1 && range / day <= 2) {
        return '昨天'
    } else if (range / day > 2) {
        return '3天前'
    }
}

export const scrollToTop = () => {
    const fromTopDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
    if (fromTopDistance > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, fromTopDistance - fromTopDistance / 8);
    }
};
