/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:55:37
 */
const basic: Map<string, string> = new Map([
  ['getMemberInfo', '/elebuys/member/employee/member-info'], // 获取会员信息
  ["send_code", "/basic/sms/sms-code"], // 发送验证码
  ["upload_single_file", "/basic/file/upload"], // 上传单个文件
  ["upload_multiple_file", "/basic/file/batchUpload"], // 上传多个文件
  ["get_address", "/basic/area/areas"], // 获取地址
]);

export default basic;