/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-11 13:40:28
 * @LastEditors: asheng
 * @LastEditTime: 2021-04-01 13:37:55
 */

import React from "react";
import Loadable from "react-loadable";
import { Loading } from "components";
import { Route, Switch, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { Header, Footer } from "components";
import PayLayout from "../../views/Pay/index";
import Success from "../../views/Success/index";
import "./index.less";

const Basic: React.FC = () => {
  return (
    <div>
      <Header></Header>
      <Switch>
        <Route path="/pay" exact component={PayLayout} />
        <Route path="/success" exact component={Success} />

        <Redirect from="/" to="/pay" />
      </Switch>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default observer(Basic);
