/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-05-26 15:55:02
 */

type envType = string | undefined;
const ENV: envType = process.env.REACT_APP_API_ENV||'dev';

// API 接口
const API_HOST: Map<envType, string> = new Map([
  ["dev", "https://dev-gateway.fxqifu.net/pangu"],
  ["qa", "https://qa-gateway.fxqifu.net/pangu"],
  ["prod", "https://gateway.fxqifu.net/pangu"],
]);
const MAIN_HOST = API_HOST.get(ENV);

const getPeliten = (loginLink: any) => {
  const map:any = new Map([
    ['dev', `https://${loginLink || 'dev-www.peliten.com'}`],
    ['qa', `https://${loginLink || 'qa-www.peliten.com'}`],
    ['prod', `https://${loginLink || 'www.peliten.com'}`],
  ])
  return map.get(ENV)
}

const MALL: Map<envType, string> = new Map([
  ["dev", "http://dev-www.elebuys.com/index"],
  ["qa", "http://qa-www.elebuys.com/index"],
  ["prod", "https://mall.fxqifu.com/index"],
]);
const MALL_HOST = MALL.get(ENV);

export {
  MAIN_HOST,
  MALL_HOST,
  getPeliten
};
