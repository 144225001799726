import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import "./index.less";
import Cookies, { set } from "js-cookie";
import { PayModal } from "components";
import { Button, Spin, Statistic, Input, message, Modal, Space } from "antd";
import { parseQueryString, ExportFile, copyStr } from "tools/common";
import { InfoCircleOutlined } from "@ant-design/icons";
import VerifyCode from "@fle-ui/plus-verify-code";
import { AccountStore, CommonStore } from "stores";
import { account } from "api";
import { MAIN_HOST } from "config";
import { getPeliten } from "config/url";
interface MainProps {
  [key: string]: any;
}

const { Countdown } = Statistic;

const formatPriceMy = (val: number) => {
  var str = (val / 100).toFixed(2) + "";
  var intSum = str
    .substring(0, str.indexOf("."))
    .replace(/\B(?=(?:\d{3})+$)/g, ","); //取到整数部分
  var dot = str.substring(str.length, str.indexOf(".")); //取到小数部分搜索
  var ret = intSum + dot;
  return ret;
};

const statusMap: any = new Map([
  [
    1,
    [
      "在批量付款时，以下支付方式不可用：",
      "（如需使用请返回订单列表，对每笔订单单独支付）",
    ],
  ],
  [2, ["以下支付方式不可用：", ""]],
]);

const rightTopImg =
  "https://oss.elebuys.com/resource/shoppingmall/202106151144210003960964.png";

const hiddenProperty: any =
  "hidden" in document
    ? "hidden"
    : "webkitHidden" in document
    ? "webkitHidden"
    : "mozHidden" in document
    ? "mozHidden"
    : null;
const visibilityChangeEvent = hiddenProperty.replace(
  /hidden/i,
  "visibilitychange"
);

/**
 *
 * 收银台
 */
const PayLayout: React.FC<MainProps> = (props): JSX.Element => {
  const [status, setStatus] = useState<number>(2);

  const history = useHistory();

  const [payBillIds, setPayBillIds] = useState<any>([]); // 交易id列表

  const [step, setStep] = useState<number>(1); // 1 选择支付方式 2 bankpay

  const [noMoney, setNoMoney] = useState<boolean>(false); // 预付货款余额不足
  const [balance, setBalance] = useState<number>(0); // 预付货款余额

  useEffect(() => {
    const obj = parseQueryString();
    if (!obj.payBillIds) {
      CommonStore.setShowErrModal(true);
      return;
    }
    const payBillIds = (obj?.payBillIds || "").split(",");
    setPayBillIds(payBillIds);
  }, []);

  useEffect(() => {
    if (payBillIds.length > 1) {
      setStatus(1);
    }
  }, [payBillIds]);

  const [paymentData, setPaymentData] = useState<any>({}); // 收银台初始化信息

  const [payMethods, setPayMethods] = useState<any[]>([]); // 可以使用的支付方式

  const [payMethodsDisabled, setPayMethodsDisabled] = useState<any[]>([]); // 不可以使用的支付方式

  const [loading, setLoading] = useState<boolean>(false); // 支付loading
  const [mallMemo, setMallMemo] = useState<string>("");
  const [bankCodeMall, setBankCode] = useState<any>();
  // 收银台初始化 获取支付方式等信息
  const getPaymentList = async (payBillIds: any) => {
    if (payBillIds.length < 1) return;
    try {
      setLoading(true);

      const res =
        CommonStore.platform == "190"
          ? await AccountStore.mallPayList({
              payBillIdList: payBillIds,
            })
          : await AccountStore.getPaymentList({
              payBillIdList: payBillIds,
            });

      if (res?.success) {
        setPaymentData(res.data);
        setBankCode(res.data.defaultPay.payDetail.code);
        CommonStore.setBaseInfo({
          logo: res.data.logo,
        });
        setDeadTime(
          new Date(res.data.payEndTime).getTime() +
            new Date().getTime() -
            new Date(res.data.currentTime).getTime()
        );
      }
    } catch (error: any) {
      if (["400012", "400075"].includes(error?.code)) {
        setOrderChangeModalShow(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    mallPayInnfo();
    if (payBillIds.length > 0) {
      getPaymentList(payBillIds);
      setPayInfo({
        img: "https://oss.elebuys.com/resource/shoppingmall/202106232139180003962493.png",
        topText: "批量付款",
        botText: `共${payBillIds.length}笔订单`,
      });
    }
    if (CommonStore.platform == "190") {
      console.log("11");
    } else {
      if (payBillIds.length === 1) {
        if (CommonStore.type === "3") {
          // 发货单
          setPayInfo({
            img: "https://oss.elebuys.com/resource/shoppingmall/202106232139180003962493.png",
            topText: "付款",
            botText: `共1笔订单`,
          });
        } else if (CommonStore.type === "4") {
          // 短信包
          setPayInfo({
            img: "https://oss.elebuys.com/resource/shoppingmall/202106232139180003962493.png",
            topText: "付款",
            botText: `共1笔订单`,
          });
        } else if (CommonStore.type === "5") {
          // 卡券电商卡券订单
          setPayInfo({
            img: "https://oss.elebuys.com/resource/shoppingmall/202106232139180003962493.png",
            topText: "付款",
            botText: `共1笔订单`,
          });
        } else {
          // 单个订单则获取顶部信息
          getOrderInfoByPayBillNo();
        }
      }
    }
  }, [payBillIds]);

  const onVisibilityChange = () => {
    // 当可见性改变触发函数
    const doc: any = document;
    if (!doc[hiddenProperty]) {
      const obj = parseQueryString();
      if (!obj?.payBillIds) {
        CommonStore.setShowErrModal(true);
        return;
      }
      const payBillIds = (obj?.payBillIds || "").split(",");
      getPaymentList(payBillIds);
    } else {
      // console.log("离开");
    }
  };

  useEffect(() => {
    document.addEventListener(visibilityChangeEvent, onVisibilityChange);
    return () => {
      document.removeEventListener(visibilityChangeEvent, onVisibilityChange);
    };
  }, []);

  // 单个订单简要信息
  const [orderInfoByPayBillNo, setOrderInfoByPayBillNo] = useState<any>({});
  const [getMallGoodsInfo, setGetMallGoodsInfo] = useState<any>({});

  const mallPayInnfo = async () => {
    if (CommonStore.platform == "190") {
      const res = await AccountStore.mallPay({
        payBillId: payBillIds[0],
      });
      if (res.success) {
        setMallMemo(res.data.memo);
        console.log(res.data);
      }
    }
  };
  const getOrderInfoByPayBillNo = async () => {
    try {
      const res = await AccountStore.getOrderInfoByPayBillNo({
        payBillNo: payBillIds[0],
      });
      if (res?.success) {
        setPayInfo({
          img: res.data.goodsImage,
          topText: `订单编号：${res.data.orderNo}`,
          botText: `商品名称：${res.data.goodsName}`,
        });
        setOrderInfoByPayBillNo(res.data);
      }
    } catch (error: any) {
      if (error?.code === "800108") {
        setOrderChangeModalShow(true);
      }
    }
  };

  const [orderChangeModalShow, setOrderChangeModalShow] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  useEffect(() => {
    const payMethods: any[] = [];
    const payMethodsDisabled: any[] = [];
    paymentData?.defaultPay?.payDetail &&
      payMethods.push(paymentData?.defaultPay?.payDetail);
    if (paymentData?.payChannel?.detailList?.length > 0) {
      paymentData?.payChannel?.detailList.forEach((item: any) => {
        if (item.canUse === true) {
          payMethods.push(item);
        } else {
          payMethodsDisabled.push(item);
        }
      });
    }
    setPayMethods(payMethods);
    setPayMethodsDisabled(payMethodsDisabled);
    setCheckedMethod(payMethods[0]);

    const item = payMethods.find((item: any) => item.code === "10");
    if (item) {
      setNoMoney(!item.canUse);
      setBalance(Number(item.balance));
    }
  }, [paymentData]);

  // 顶部展示的一些信息
  const [payInfo, setPayInfo] = useState<any>({});
  const [mobiles, setMobiles] = useState<string>("");
  const [checkedMethod, setCheckedMethod] = useState<any>({}); // 选择的支付方式
  const handleClickMethod = (method: any) => {
    setCheckedMethod(method);
  };

  // 立即支付
  const pay = () => {
    setIsShowPayModal(true);
  };

  // 去充值
  const goToRecharge = async () => {
    try {
      const res = await CommonStore.getMemberInfo();
      if (res?.success) {
        const loginLink = res?.data?.loginLink;
        window.open(
          `${getPeliten(
            loginLink
          )}/home/application/workbench/finance/account/recharge?token=${Cookies.get(
            "pay_token"
          )}`
        );
      }
    } catch (error) {}
  };

  // 对公账户 去付款 点击
  const handleBankPay = () => {
    setStep(2);
  };

  const verifySlideFixedChild = (data: any) => {
    if (data.captchaVerification) {
      AccountStore.sendSmres({
        captchaVerification: data.captchaVerification,
      }).then((res: any) => {
        if (res.success) {
          message.success("发送成功");
          setDisabledBotton(true);
          setButtonCon(60);
          scmNum();
          AccountStore.sendSmss();
        }
      });
      setSlideShow(false);
    }
  };
  const openPay = async () => {
    const res: any = await AccountStore.getPayMobile();
    if (res.success) {
      setMobiles(res.data);
      setIsModalOpen(true);
      scmNum();
    }
  };
  const renderPrice = (val: number = 0) => {
    const arr = formatPriceMy(val).toString().split(".");
    return (
      <div>
        <span className="p_layout_payInfo_price_1">{arr[0]}.</span>
        <span className="p_layout_payInfo_price_2">{arr[1]}</span>
      </div>
    );
  };

  const renderMainBox = () => {
    return (
      <Spin spinning={loading}>
        <div className="main_box">
          {payMethods.map((item: any, index: number) => {
            return (
              <div
                className={`pay_method ${
                  checkedMethod.code === item.code ? "pay_method_checked" : ""
                }`}
                onClick={() => handleClickMethod(item)}
                key={index}
              >
                <img
                  src={rightTopImg}
                  alt="logo"
                  className="pay_method_rightTopImg"
                />
                <img src={item.logo} alt="logo" className="pay_method_logo" />
                <div className="pay_method_intro">
                  <div className="pay_method_intro_title">{item.name}</div>
                  <div className="pay_method_intro_desc">{item.desc}</div>
                </div>
                <div className="pay_method_right">
                  {item.balance !== null && item.code === "10" && (
                    <div className="pay_method_right_balance">
                      账户可用余额: ￥{formatPriceMy(item.balance)}
                    </div>
                  )}
                  {!!item.accountDesc && (
                    <div className="pay_method_right_insufficient">
                      {item.accountDesc}
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {payMethodsDisabled.length > 0 && status > 0 && (
            <div className="main_box_midText">
              <span className="main_box_midText_big">
                {statusMap.get(status)[0]}
              </span>
              <span className="main_box_midText_small">
                {statusMap.get(status)[1]}
              </span>
            </div>
          )}

          {payMethodsDisabled.map((item: any, index: number) => {
            return (
              <div
                className={`pay_method ${
                  checkedMethod.code === item.code ? "pay_method_checked" : ""
                }`}
                key={index}
                style={{ cursor: "not-allowed" }}
              >
                <img
                  src={rightTopImg}
                  alt="logo"
                  className="pay_method_rightTopImg"
                />
                <img src={item.logo} alt="logo" className="pay_method_logo" />
                <div className="pay_method_intro">
                  <div className="pay_method_intro_title">{item.name}</div>
                  <div className="pay_method_intro_desc">{item.desc}</div>
                </div>
                <div className="pay_method_right">
                  {/* {item.balance !== null && <div className="pay_method_right_balance">
                    账户可用余额: ￥{formatPriceMy(item.balance)}
                  </div>} */}
                  {!!item.accountDesc && (
                    <div className="pay_method_right_insufficient">
                      {item.accountDesc}
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {checkedMethod?.code === "10" && !noMoney && (
            <Button type="primary" onClick={pay} className="pay_btn">
              立即支付
            </Button>
          )}

          {checkedMethod?.code === "10" && noMoney && (
            <Button type="primary" onClick={goToRecharge} className="pay_btn">
              去充值
            </Button>
          )}

          {checkedMethod?.code === "13" && (
            <Button type="primary" onClick={handleBankPay} className="pay_btn">
              去付款
            </Button>
          )}
          {CommonStore.platform === "190" && (
            <Button type="primary" onClick={openPay} className="pay_btn">
              去付款
            </Button>
          )}
        </div>
      </Spin>
    );
  };

  const [deadTime, setDeadTime] = useState<any>(0); // 倒计时

  // 对公账户begin ----------------------------

  const [phone, setPhone] = useState<string>(""); // 手机号

  // 点击 生成专属付款申请单
  const handleClickDown = async () => {
    try {
      const params = {
        payBillId: payBillIds[0],
        orderNo: orderInfoByPayBillNo?.orderNo,
      };
      const hide = message.loading("导出中，请稍后...");
      ExportFile(
        `${MAIN_HOST}${account.get("downloadPdf")}`,
        params,
        "",
        () => {
          message.success("导出成功");
          hide();
        }
      );
    } catch (error) {}
  };

  // 点击 发送短信
  const sendMsg = async () => {
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      return message.warning("请输入正确的手机号码");
    }
    try {
      const params = {
        mobile: phone,
        payBillId: payBillIds[0],
        accountId: shopAccount.id,
      };
      const res =
        CommonStore.platform == "190"
          ? await AccountStore.sendSmres(params)
          : await AccountStore.sendSms(params);
      if (res?.success) {
        message.success(`已发送短信至${phone}`);
      }
    } catch (error) {}
  };

  const [shopAccount, setShopAccount] = useState<any>({}); // 卖家对公账户

  const getShopAccount = async () => {
    const params = {
      payBillId: payBillIds[0],
    };
    try {
      const res = await AccountStore.getAccount(params);
      if (res?.success) {
        setShopAccount(res.data);
        shopAccountPay(res.data);
      }
    } catch (error) {}
  };

  const shopAccountPay = async (data: any) => {
    try {
      const params = {
        amtPay: paymentData.payMoney,
        channelInfoList: [
          {
            bankCode: "13",
            channelAmtPay: paymentData.payMoney,
            cardNo: data.bankCode,
          },
        ],
        payBillInfoList: [
          {
            payBillId: payBillIds[0],
          },
        ],
      };
      AccountStore.pay(params);
    } finally {
    }
  };

  // 点击一键复制
  const copyAccount = () => {
    let str = ``;
    str += `收款户名 ${shopAccount.accountName} \n`;
    str += `收款账号 ${shopAccount.bankCode} \n`;
    str += `开户银行 ${shopAccount.bankName} \n`;
    str += `汇款备注 飞象商城订单号：${orderInfoByPayBillNo?.orderNo}`;
    copyStr(str);
    message.success("复制成功");
  };

  useEffect(() => {
    if (step === 2) {
      if (!orderInfoByPayBillNo?.orderNo) return;
      getShopAccount();
    }
    console.log(step);
  }, [step]);

  // 对公账户转账box
  const renderBankPayBox = () => {
    return (
      <div className="bank_box">
        <div className="bank_box_header">
          <span className="bank_box_header_title">操作步骤</span>
          <span className="bank_box_header_time">
            请在 <Countdown value={deadTime} format="D天H时m分s秒" /> 内完成支付
          </span>
        </div>
        <div className="bank_box_header_line"></div>
        <div className="bank_box_step" style={{ marginBottom: 16 }}>
          <span className="bank_box_step_num">1</span>
          <span className="bank_box_step_text">
            打开手机银行APP/PC网银，或前往银行柜台
          </span>
        </div>
        <div className="bank_box_step" style={{ marginBottom: 10 }}>
          <span className="bank_box_step_num">2</span>
          <span className="bank_box_step_text">
            用您的银行卡向以下账户进行转账
          </span>
        </div>
        <div className="bank_box_info">
          <div className="collection_account_info">
            <div className="collection_account_info_title">
              <span>收款账户信息</span>
              <span
                style={{ cursor: "pointer", color: "#f00" }}
                onClick={copyAccount}
              >
                一键复制
              </span>
            </div>
            <div className="collection_account_info_item">
              <div className="collection_account_info_item_key">收款户名</div>
              <div className="collection_account_info_item_value">
                {shopAccount.accountName}
              </div>
            </div>
            <div className="collection_account_info_item">
              <div className="collection_account_info_item_key">收款账号</div>
              <div
                className="collection_account_info_item_value"
                style={{ color: "#FF0700" }}
              >
                {shopAccount.bankCode}
              </div>
            </div>
            <div className="collection_account_info_item">
              <div className="collection_account_info_item_key">开户银行</div>
              <div className="collection_account_info_item_value">
                {shopAccount.bankName}
              </div>
            </div>
            <div className="collection_account_info_item">
              <div className="collection_account_info_item_key">汇款备注</div>
              <div
                className="collection_account_info_item_value"
                style={{ color: "#FF0700" }}
              >
                飞象商城订单号：{orderInfoByPayBillNo?.orderNo}
              </div>
            </div>
          </div>
          <div className="bank_box_info_line"></div>
          <div className="bank_box_info_right">
            <div className="bank_box_info_right_text">
              请通知财务尽快完成转账记录
            </div>
            <div className="bank_box_info_right_btn" onClick={handleClickDown}>
              生成专属付款申请单
            </div>
            <div className="bank_box_info_right_text">将转账信息发送到手机</div>
            <div className="bank_box_info_right_message">
              <Input
                style={{ width: 174 }}
                placeholder="请输入手机号"
                value={phone}
                onChange={(e: any) => {
                  setPhone(e.target.value);
                }}
              ></Input>
              <div
                className="bank_box_info_right_message_sendbtn"
                onClick={sendMsg}
              >
                发送
              </div>
            </div>
          </div>
        </div>

        <div className="bank_box_step" style={{ marginTop: 10 }}>
          <span className="bank_box_step_num">3</span>
          <span className="bank_box_step_text">
            卖家核查到账信息，将订单标记为“买家已付款”
          </span>
        </div>
      </div>
    );
  };

  // 对公账户end ----------------------------
  const [payResultLoading, setPayResultLoading] = useState(false);
  const [isShowPayModal, setIsShowPayModal] = useState<boolean>(false);
  const [phoneValue, setphoneValue] = useState<string>("");
  const [remainCount, setRemainCount] = useState<number>(0);
  const [smsCodeWrongCount, setSmsCodeWrongCount] = useState<number>(0);
  const [slideShow, setSlideShow] = useState(false);
  const [disabledBotton, setDisabledBotton] = useState(false);
  const [buttonCon, setButtonCon] = useState<number>(0);
  const [errorPay, setErrorPay] = useState("");
  const fleAppEnv: any = process.env.REACT_APP_API_ENV;
  useEffect(() => {
    var intervalId: any;
    if (buttonCon > 0) {
      intervalId = window.setInterval(() => {
        setButtonCon((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setDisabledBotton(false);
      window.clearInterval(intervalId);
    }
    return () => window.clearInterval(intervalId);
  }, [buttonCon]);
  const handleCancel = () => {
    setIsModalOpen(false);
    setButtonCon(0);
    setMobiles("");
  };
  const getPicture = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        AccountStore.getPicture(params).then((res: any) => {
          resolve(res.data);
        });
      } catch (ex) {
        reject();
      }
    });
  };
  const submitPhone = async () => {
    scmNum();
    setSlideShow(true);
  };
  const scmNum = async () => {
    const res: any = await AccountStore.smsCount();
    if (res.success) {
      setRemainCount(res.data.remainCount);
      setSmsCodeWrongCount(res.data.smsCodeWrongCount);
    }
  };
  const onChange = (text: any) => {
    setphoneValue(text.target.value);
  };
  const handleOk = async () => {
    if (!disabledBotton) {
      message.error("请先获取验证码");
      return false;
    }
    if (!phoneValue) {
      message.error("请输入验证码");
      return false;
    }
    const params = {
      amtPay: paymentData.payMoney,
      channelInfoList: [
        {
          payVerifyCode: phoneValue,
          payMobile: mobiles,
          bankCode: bankCodeMall,
          channelAmtPay: paymentData.payMoney,
        },
      ],

      payBillInfoList: [
        {
          payBillId: payBillIds[0],
        },
      ],
    };
    const { success, data } = await AccountStore.goPay(params);
    if (success) {
      message.success("支付成功");
      setTimeout(() => {
        window.location.href = `${CommonStore.redirectUrl}`;
      }, 500);
    } else {
    }
  };
  // 支付成功
  const onPaySuccess = () => {
    setIsShowPayModal(false);
    if (CommonStore.autoRedirect === "1") {
      // 如果设置了自动跳转则不打开支付成功页 2s后直接跳转
      message.success("支付成功");
      setTimeout(() => {
        if (CommonStore.redirectTokenFlag == "1") {
          window.location.href = `${
            CommonStore.redirectUrl
          }?token=${Cookies.get("pay_token")}`;
        } else {
          window.location.href = `${CommonStore.redirectUrl}`;
        }
      }, 1500);
    } else {
      history.push("/success");
    }
  };

  return (
    <div className="p_layout">
      {CommonStore.platform != "190" && (
        <div className="p_layout_payInfo">
          <img src={payInfo.img} alt="img" />
          <div className="p_layout_payInfo_main">
            <div className="p_layout_payInfo_main_topText">
              {payInfo.topText}
            </div>
            <div className="p_layout_payInfo_main_botText">
              <span className="p_layout_payInfo_main_botText_text">
                {payInfo.botText}
              </span>
              {orderInfoByPayBillNo?.orderNo &&
                orderInfoByPayBillNo?.goodsCount > 1 && (
                  <span style={{ marginLeft: 10 }}>
                    等{orderInfoByPayBillNo?.goodsCount}件商品
                  </span>
                )}
            </div>
          </div>
          <div className="p_layout_payInfo_price">
            <span className="p_layout_payInfo_price_icon">￥</span>
            {renderPrice(paymentData.payMoney)}
          </div>
        </div>
      )}
      {CommonStore.platform == "190" && (
        <div>
          <div className="p_layout_payInfo">
            <img
              src="https://qa-oss.elebuys.com/resource/goods/banner/202408311256470001445563.png"
              alt="img"
            />
            <div className="p_layout_payInfo_main">
              <div className="p_layout_payInfo_main_topText">
                支付单号:{payBillIds[0]}
              </div>
            </div>
            <div className="p_layout_payInfo_price">
              <span className="p_layout_payInfo_price_icon">￥</span>
              {renderPrice(paymentData.payMoney)}
            </div>
          </div>
        </div>
      )}
      {step === 1 && renderMainBox()}
      {step === 2 && renderBankPayBox()}
      {isShowPayModal && (
        <PayModal
          onClose={() => {
            setIsShowPayModal(false);
          }}
          onPaySuccess={onPaySuccess}
          paymentData={paymentData}
          balance={balance}
          payBillIds={payBillIds}
        ></PayModal>
      )}
      {orderChangeModalShow && <OrderChangeModal></OrderChangeModal>}
      {slideShow && (
        <VerifyCode
          env={fleAppEnv}
          visible={slideShow}
          onClose={() => setSlideShow(false)}
          onSuccess={verifySlideFixedChild}
        />
      )}
      <Modal
        title="提示"
        visible={isModalOpen}
        footer={[
          <Button key="back" loading={payResultLoading} onClick={handleCancel}>
            取消支付
          </Button>,
          <Button
            type="primary"
            disabled={remainCount == 0}
            loading={payResultLoading}
            onClick={handleOk}
          >
            支付
          </Button>,
        ]}
        closable={false}
      >
        <div className="priceChangeModal">
          <div>
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  color: "#C91623",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                ￥
              </span>
              <span
                style={{
                  color: "#C91623",
                  fontWeight: "bold",
                  fontSize: "32px",
                }}
              >
                {paymentData.payMoney / 100}
              </span>
            </div>
            <div
              style={{
                color: "#333",
                fontWeight: "bold",
                marginBottom: "16px",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              {mobiles}
              <div
                style={{
                  color: "#C91623",
                  marginBottom: "10px",
                  textAlign: "center",
                  fontSize: "10px",
                }}
              >
                请使用以上号码获取验证码进行支付操作
              </div>
            </div>
            <div>
              <Space.Compact style={{ width: "100%" }}>
                <Input onChange={onChange} placeholder="请输入验证码" />
                <Button
                  disabled={disabledBotton}
                  type="primary"
                  onClick={submitPhone}
                >
                  {disabledBotton ? buttonCon + "s后重试" : "发送验证码"}
                </Button>
              </Space.Compact>
              <div
                style={{
                  marginTop: "16px",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {(remainCount == 0 || !remainCount) && (
                  <div style={{ fontSize: "12px", color: "#c1c1c1" }}>
                    超出每日额定错误次数，请联系系统管理员或明日再试
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

// 订单变化弹窗
const OrderChangeModal = (props: any) => {
  // 查看订单变动
  const handleClickOrder = () => {
    window.open(`${CommonStore.redirectUrl}?token=${Cookies.get("pay_token")}`);
  };
  return (
    <Modal
      title="提示"
      visible={true}
      footer={[
        <Button key={1} onClick={handleClickOrder} type="primary">
          查看订单变动
        </Button>,
      ]}
      closable={false}
    >
      <div className="priceChangeModal">
        <div>
          <InfoCircleOutlined style={{ fontSize: 18, color: "red" }} />
          <span style={{ marginLeft: 10, fontSize: 16 }}>订单状态发生变化</span>
        </div>
      </div>
    </Modal>
  );
};
export default observer(PayLayout);
