import React from "react";
import { observer } from "mobx-react";
import "./index.less";
import { CommonStore } from "stores";
interface MainProps {
  [key: string]: any;
}

/**
 *
 * Header
 */
const Header: React.FC<MainProps> = (props): JSX.Element => {
  return (
    <div className="header">
      <div className="header_wrap">
        {/* <img
          src={CommonStore?.baseInfo?.logo || 'https://oss.elebuys.com/tmpdir/202106092118010003959999.png'}
          alt="logo"
        />
        <div className="line"></div> */}
        <div className="title" style={{ width: "100%", textAlign: "center" }}>
          收银台
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
