/*
 * @Description: description
 * @Author: asheng
 * @Date: 2021-03-22 16:53:05
 * @LastEditors: asheng
 * @LastEditTime: 2021-03-31 19:24:01
 */
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Loadable from "react-loadable";
import { observer } from "mobx-react";
import "./App.less";
import { Loading } from "components";
import { parseQueryString } from "tools/common";
import Cookies from "js-cookie";
import { CommonStore } from "stores";
import { Alert, Modal } from "antd";
import Basic from "./layout/Basic";
function App() {
  useEffect(() => {
    const obj = parseQueryString();
    if (!obj.payBillIds) {
      CommonStore.setShowErrModal(true);
      return;
    } 
    console.log(obj, "obj");
    const {
      token = "",
      redirectUrl = "",
      autoRedirect = "0",
      type = "",
      redirectTokenFlag = "1",
      platform = "",
    } = obj;
    if (!!token) {
      Cookies.set("pay_token", token);
    }
    if (!!redirectUrl) {
      CommonStore.setRedirectUrl(decodeURIComponent(redirectUrl));
    }
    if (!!autoRedirect) {
      CommonStore.setAutoRedirect(autoRedirect);
    }
    if (!!type) {
      CommonStore.setType(type);
    }
    if (!!platform) {
      CommonStore.setPlatform(platform);
    }
    if (!!redirectTokenFlag) {
      CommonStore.setRedirectTokenFlag(redirectTokenFlag);
    }
  }, []);
  return (
    <div className="App">
      {!CommonStore.showErrModal && (
        <BrowserRouter>
          <Basic />
        </BrowserRouter>
      )}
      {CommonStore.showErrModal && <ErrModal></ErrModal>}
    </div>
  );
}

// 收银台信息错误弹窗
const ErrModal = () => {
  return (
    <Modal title="温馨提示" visible={true} closable={false} footer={null}>
      <Alert
        message="收银台信息错误"
        description="3秒后关闭收银台"
        type="error"
      />
    </Modal>
  );
};

export default observer(App);
