import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  Method,
} from "axios";
import { notification } from "antd";
import { MAIN_HOST, DATA_CODE } from "config";
import Cookies from "js-cookie";

interface RequestConfig extends AxiosRequestConfig {
  notice?: boolean;
}

interface PendingType {
  url?: string;
  method?: Method;
  params: any;
  data: any;
  cancel: Function;
}

// 登录失效
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const service = axios.create({
  baseURL: MAIN_HOST,
  timeout: 10000,
});

const pending: Array<PendingType> = [];

// 移除重复请求
const removePending = (config: RequestConfig) => {
  for (const key in pending) {
    const item: number = +key;
    const list: PendingType = pending[key];
    // 当前请求在数组中存在时执行函数体
    if (
      list.url === config.url &&
      list.method === config.method &&
      JSON.stringify(list.params) === JSON.stringify(config.params) &&
      JSON.stringify(list.data) === JSON.stringify(config.data)
    ) {
      // 执行取消操作
      list.cancel("操作太频繁，请稍后再试");
      // 从数组中移除记录
      pending.splice(item, 1);
    }
  }
};

service.interceptors.request.use(
  (config: RequestConfig) => {
    removePending(config);

    config.headers["authority_token"] = Cookies.get("pay_token") || '';
    // config.url = `${config.url}?__date=${new Date().getTime()}`;
    config.url = `${config.url}`;

    return {
      ...config,
      cancelToken: source.token,
    };
  },
  (err: AxiosError) => {
    notification.error({
      message: "提示",
      description: err.toString(),
    });
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (res: AxiosResponse) => {
    const { status, data, statusText, config } = res;

    removePending(config);

    return new Promise<any>((resolve, reject) => {
      if (status < 200 || status > 300) {
        notification.error({
          message: `请求错误: ${status}`,
          description: statusText,
        });
        reject(data);
      } else if (!data.success) {

        // if (data.code === '915003') {
        //   return reject(data);
        // }

        if (data.code === DATA_CODE.get("LOGIN_ERROR")) {
          // 登录失效后，取消后续的http请求
          source.cancel("登录失效");
          Cookies.remove("pay_token", { domain: '.elebuys.com' });
          // Modal.warning({
          //   title: "登录失效",
          //   content: "点击确定重新登录。",
          //   okText: "确定",
          //   onOk: () => {
          //     // CommonStore.loginF();
          //     window.location.href = getLogin() || '';
          //   },
          // });
          notification.error({
            message: "提示",
            description: data.message,
          });
          return reject(data);
        }

        if (['400070','800108','400012','400075'].includes(data.code)) {
          return reject(data);
        }

        notification.error({
          message: "提示",
          description: data.message,
        });

        reject(data);
      } else {
        resolve(data);
      }
    });
  },
  (err: AxiosError) => {
    if (err.message === '登录失效') {
      return
    }
    notification.error({
      message: "提示",
      description: err.toString(),
    });
    return Promise.reject();
  }
);

function get<T = any>(url: string, config?: RequestConfig): Promise<T> {
  return service.get(url, config);
}

function post<T = any>(
  url: string,
  data?: any,
  config?: RequestConfig
): Promise<T> {
  return service.post(url, data, config);
}

//转换类型
declare module "axios" {
  interface AxiosInstance {
    (config: RequestConfig): Promise<any>;
  }
}

export default {
  get,
  post,
};

